// @ts-check
import {
  useTheme,
  Button,
  View,
  Heading,
  Text,
  Image,
  ThemeProvider,
  Authenticator,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import { checkAdminStatus } from "helpers/cognito";
import Swal from "sweetalert2";

export function AuthStyle() {
  const { tokens } = useTheme();

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Logo de BMaker"
            src={require("./assets/Colorlogonobackground.png")}
            style={{ width: "16rem", height: "auto" }}
          />
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; BMaker Pro - Todos los derechos reservados
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Inicia sesión en tu cuenta
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Restablecer contraseña
            </Button>
          </View>
        );
      },
    },
    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Crea una nueva cuenta
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Volver a Iniciar Sesión
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
      Footer() {
        return <Text>No te preocupes, te respaldamos!</Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: "Ingresa tu correo electrónico",
      },
      password: {
        label: "Contraseña:",
        placeholder: "Ingresa tu contraseña",
      },
    },
    signUp: {
      password: {
        label: "Contraseña:",
        placeholder: "Ingresa tu contraseña:",
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: "Confirmar contraseña:",
        order: 1,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Ingresa tu contraseña:",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Ingresa tu correo electrónico:",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Ingresa tu código de Confirmación:",
        label: "Nuevo Etiqueta",
        isRequired: false,
      },
      confirm_password: {
        placeholder: "Ingresa tu contraseña:",
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "emisor de prueba",
        totpUsername: "usuario_prueba_amplify_qr",
      },
      confirmation_code: {
        label: "Nuevo Etiqueta",
        placeholder: "Ingresa tu código de confirmación:",
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "Nuevo Etiqueta",
        placeholder: "Ingresa tu código de confirmación:",
        isRequired: false,
      },
    },
  };
  const theme = {
    name: "Auth Example Theme",
    tokens: {
      colors: {
        brand: {
          primary: {
            10: tokens.colors.blue["200"],
            80: "#5096cb",
            90: tokens.colors.blue["20"],
            100: tokens.colors.blue["10"],
          },
        },
      },
      components: {
        tabs: {
          item: {
            _hover: {
              color: {
                value: tokens.colors.blue["80"].value,
              },
            },
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        services={{
          async handleSignIn(input) {
            try {
              let { username, password } = input;
              const user = await Auth.signIn({
                username,
                password,
              });
              if (user) {
                if (!(await checkAdminStatus())) {
                  await Auth.signOut();
                  await Swal.fire({
                    title: "Ups! Parece que te desviaste.",
                    text: "No tienes permiso para acceder a esta sección. Serás redirigido al portal para clientes.",
                    icon: "error",
                    confirmButtonText: "Redirigir",
                  });
                  window.location.href = "https://app.bmaker.pro/";
                }
              }
              return user;
            } catch (err) {
              console.log({ err });
            }
          },
        }}
        formFields={formFields}
        components={components}
        hideSignUp
      ></Authenticator>
    </ThemeProvider>
  );
}
