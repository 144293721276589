// Chakra imports
import {
  Portal,
  useDisclosure,
  Stack,
  Box,
  useColorMode,
  Image,
} from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
// Custom Chakra theme
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";

import LogoDark from "../assets/img/LogoTIPSparaDark.png";
import LogoLight from "../assets/img/LogoTIPSparaLight.png";

import BackgroundDashboard from "components/Animations/Background/BackgroundDashboard";
import { API, graphqlOperation, Auth } from "aws-amplify";
import * as queries from "../graphql/queries.js";
import { createUser } from "../graphql/mutations";

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed, setFixed] = useState(false);
  const { colorMode } = useColorMode();

  const [profile, setProfile] = useState({});
  const [user, setUser] = useState({});

  const listaUsuariosQuePuedenVerUsuarios = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];
  const listaUsuariosQuePuedenVerSignals = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];
  const listaUsuariosQuePuedenVerTravels = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];
  const listaUsuariosQuePuedenVerNews = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];
  const listaUsuariosQuePuedenVerLives = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];
  const listaUsuariosQuePuedenVerTutorials = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];
  const listaUsuariosQuePuedenVerSocialTrading = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];
  const listaUsuariosQuePuedenVerAuditedAccounts = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];
  const listaUsuariosQuePuedenVerMentalidad = [
    "mauro.henaog@gmail.com",
    "juand.ardilag@gmail.com",
    "lauramirez0924@gmail.com",
    "juan.d.ardila.g@gmail.com",
    "betarplay@gmail.com",
    "mvaacademydigital@mvaglobal.net",
  ];

  async function createUserMethod(user) {
    if (user?.attributes) {
      try {
        await API.graphql(
          graphqlOperation(createUser, {
            input: {
              id: user.attributes.sub,
              username: user.username,
              name: user.attributes.name,
              phone: user.attributes.phone_number,
              email: user.attributes.email,
              isCommercial: false,
            },
          }),
        );
        window.location.reload();
      } catch (err) {
        console.log("error creating user", err);
      }
    }
  }

  async function getUserProfile(sub) {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getUser, { id: sub }),
      );
      if (result) {
        setProfile(result.data.getUser);
        return result.data.getUser;
      }
      return result;
    } catch (err) {
      console.log(err);
    }
  }

  const componenteMontado = useCallback(async function () {
    const user = await Auth.currentAuthenticatedUser();
    setUser(user);
    const userProfile = await getUserProfile(user.username);
    if (!userProfile) {
      createUserMethod(user);
      return;
    }
    setProfile(userProfile);
  }, []);

  useEffect(() => {
    componenteMontado();
  }, [componenteMontado]);

  const getRoute = () => {
    return window.location.pathname !== "/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "users") {
        if (listaUsuariosQuePuedenVerUsuarios.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }
      if (prop.category === "signals") {
        if (listaUsuariosQuePuedenVerSignals.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }
      if (prop.category === "travels") {
        if (listaUsuariosQuePuedenVerTravels.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }
      if (prop.category === "news") {
        if (listaUsuariosQuePuedenVerNews.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }
      if (prop.category === "lives") {
        if (listaUsuariosQuePuedenVerLives.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }
      if (prop.category === "tutorials") {
        if (listaUsuariosQuePuedenVerTutorials.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }
      if (prop.category === "social-trading") {
        if (listaUsuariosQuePuedenVerSocialTrading.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }
      if (prop.category === "audited-accounts") {
        if (listaUsuariosQuePuedenVerAuditedAccounts.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }
      if (prop.category === "mentalidad") {
        if (listaUsuariosQuePuedenVerMentalidad.includes(profile.email)) {
          return getRoutes(prop.views);
        } else {
          return null;
        }
      }

      if (prop.layout === "/admin") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  // Chakra Color Mode

  return (
    <Box>
      <Box style={{ height: "100px" }} w="100%" position="absolute" top="0">
        <BackgroundDashboard />
      </Box>
      <Sidebar
        routes={routes}
        logo={
          <Stack direction="row" spacing="10px" align="center" justify="center">
            {colorMode === "dark" ? (
              <Image
                src={LogoDark}
                style={{ width: "10rem", height: "auto" }}
              />
            ) : (
              <Image
                src={LogoLight}
                style={{ width: "10rem", height: "auto" }}
              />
            )}
          </Stack>
        }
        display="none"
        {...rest}
      />
      <MainPanel
        w={{
          base: "100%",
          xl: "calc(100% - 275px)",
        }}
      >
        <Portal>
          {user && (
            <AdminNavbar
              onOpen={onOpen}
              brandText={getActiveRoute(routes)}
              secondary={getActiveNavbar(routes)}
              fixed={fixed}
              money={user.attributes ? user.attributes.totalReward : 0}
              {...rest}
            />
          )}
        </Portal>

        {getRoute() ? (
          <PanelContent>
            <PanelContainer>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : null}

        <Footer />
        <Portal>
          <FixedPlugin
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            onOpen={onOpen}
          />
        </Portal>
        <Configurator
          secondary={getActiveNavbar(routes)}
          isOpen={isOpen}
          onClose={onClose}
          isChecked={fixed}
          user={profile}
          onSwitch={(value) => {
            setFixed(value);
          }}
        />
      </MainPanel>
    </Box>
  );
}
