// @ts-check
import { monthNames } from "../generateMonthsList";

export const MonthlyGains = {
  initial(months) {
    return {
      [months[0].value]: "",
    };
  },
  removeRow(row, monthlyGains) {
    const newMonthlyGains = { ...monthlyGains };
    delete newMonthlyGains[row];
    return newMonthlyGains;
  },
  updateRow(row, newValue, monthlyGains) {
    const newMonthlyGains = { ...monthlyGains };
    newMonthlyGains[row] = newValue;
    return newMonthlyGains;
  },
  nextMonth(last, months) {
    return last + 1 >= months.length ? 0 : last + 1;
  },
  toShortFormat(monthlyGains) {
    return Object.keys(monthlyGains)
      .map((month) => `${month.slice(2)} ${monthlyGains[month] || 0}`)
      .join(",");
  },
  fromShortFormat(shortFormat) {
    return shortFormat
      .split(",")
      .map((item) => item.trim())
      .reduce((acc, item) => {
        const [month, gain] = item.split(" ");
        acc[month] = gain;
        return acc;
      }, {});
  },
  monthCodeToMonthName(monthCode) {
    monthCode = monthCode.slice(-4);
    const month = monthCode.slice(0, -2);
    const year = monthCode.slice(-2);

    const monthName = monthNames[parseInt(month) - 1];
    return `${monthName} 20${year}`;
  },
  toHumanReadableNames(shortFormat) {
    const monthlyGains = MonthlyGains.fromShortFormat(shortFormat);
    /**
     * @type {Record<string, string>}
     */
    const result = {};
    for (const key in monthlyGains) {
      result[MonthlyGains.monthCodeToMonthName(key)] = monthlyGains[key];
    }
    return result;
  },
};
