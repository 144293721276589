import { Button } from "@chakra-ui/react";
import Swal from "sweetalert2";

/**
 * @typedef {Object} ViewDetailsButtonProps
 * @property {string} title - title of the details section
 * @property {Record<string, any>} info - info to be displayed in the details section
 * @property {string} [label] - label of the button
 */
/**
 * ViewDetailsButton component props
 * @param {ViewDetailsButtonProps} props - input props
 * @returns {React.ReactElement}
 */
export function ViewDetailsButton({ title, info, label }) {
  return (
    <Button
      variant="link"
      colorScheme="blue"
      size="sm"
      onClick={() => {
        Swal.fire({
          title: `Detalles de: ${title}`,
          html: Object.keys(info)
            .map((key) => `<b>${key}</b>: ${info[key] || "-"}`)
            .join("<br>"),
          confirmButtonText: "Cerrar",
        });
      }}
    >
      {label || "Ver detalles"}
    </Button>
  );
}
