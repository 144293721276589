// @ts-check
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import Swal from "sweetalert2";
import { API, graphqlOperation } from "aws-amplify";
import {
  deleteAuditedAccounts,
  updateAuditedAccounts,
} from "graphql/mutations";
import { ViewDetailsButton } from "components/Tables/ViewDetailsButton";
import { MonthlyGains } from "./GainsInput/MonthlyGains";

function AuditedAccountsRowTable({
  auditedAccount,
  getAllAuditedAccounts,
  isLast,
}) {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function deleteAuditedAccountHandler() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await API.graphql(
            graphqlOperation(deleteAuditedAccounts, {
              input: { id: auditedAccount.id },
            }),
          );
          Swal.fire(
            "Eliminada!",
            "La cuenta auditada ha sido eliminada.",
            "success",
          );
          getAllAuditedAccounts();
        } catch (error) {
          Swal.fire(
            "Error",
            "La cuenta auditada no ha sido eliminada.",
            "error",
          );
        }
      }
    });
  }

  async function updateMentalidadFn() {
    const { value: formValues } = await Swal.fire({
      title: "Editar Cuenta Auditada",
      html: `<input id="swal-input1" class="swal2-input" placeholder="Nombre" value="${auditedAccount.name}">
      <input id="swal-input2" class="swal2-input" placeholder="URL" value="${auditedAccount.url}">`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
    });

    if (formValues) {
      const [newName, newUrl] = formValues;

      const auditedAccountsDetails = {
        id: auditedAccount.id,
        name: newName,
        url: newUrl,
      };

      try {
        await API.graphql(
          graphqlOperation(updateAuditedAccounts, {
            input: auditedAccountsDetails,
          }),
        );
        Swal.fire(
          "Actualizada!",
          "La cuenta auditada ha sido actualizada.",
          "success",
        );
        getAllAuditedAccounts();
      } catch (error) {
        Swal.fire(
          "Error",
          "La cuenta auditada no ha sido actualizada.",
          "error",
        );
      }
    }
  }

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{auditedAccount.name}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <ViewDetailsButton
          title={auditedAccount.name}
          info={{
            ID: auditedAccount.id,
            Nombre: auditedAccount.name,
            "Ganancias por mes":
              "<br>" +
              Object.entries(
                MonthlyGains.toHumanReadableNames(auditedAccount.monthlyGains),
              )
                .map(([key, value]) => `${key} = <i>${value}%</i>`)
                .join("<br>"),
          }}
        />
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Menu>
          <MenuButton>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={deleteAuditedAccountHandler}>Eliminar</MenuItem>
            <MenuItem onClick={updateMentalidadFn}>Editar</MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default AuditedAccountsRowTable;
