// @ts-check
import { useCallback, useState } from "react";
import {
  Flex,
  FormControl,
  Table,
  Tbody,
  Text,
  Thead,
  useColorModeValue,
  Card,
} from "@chakra-ui/react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { TableInput } from "components/Tables/TableInput";
import { TableSubmitButtons } from "components/Tables/TableSubmitButtons";
import { generateMonthsList } from "views/Dashboard/AuditedAccounts/generateMonthsList";
import { API, graphqlOperation } from "aws-amplify";
import { createAuditedAccounts } from "graphql/mutations";
import Swal from "sweetalert2";
import { nanoid } from "nanoid";
import { GainsInput } from "views/Dashboard/AuditedAccounts/GainsInput/GainsInput";
import { MonthlyGains } from "views/Dashboard/AuditedAccounts/GainsInput/MonthlyGains";

export function AuditedAccountsCreateForm({ updateData, loadingData }) {
  const textColor = useColorModeValue("gray.700", "white");
  const months = useCallback(() => generateMonthsList(), [])();
  const [isLoading, setIsLoading] = useState(false);
  const [monthlyGains, setMonthlyGains] = useState(
    /** @type {Record<string,  string >} */ ({
      [months[0].value]: "",
    }),
  );
  const [lastMonthSelected, setLastMonthSelected] = useState(0);

  return (
    <Flex
      direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
      display="flex"
    >
      <Flex style={{ justifyContent: "center", alignItems: "center" }}>
        <Card
          overflowX={{ sm: "scroll", xl: "hidden" }}
          style={{ width: "40rem", padding: "1rem" }}
          pb="0px"
        >
          <CardHeader p="0px 0px 0px 0px">
            <Flex direction="column" w={{ sm: "100%" }}>
              <Text
                fontSize="xl"
                color={textColor}
                fontWeight="bold"
                pb=".5rem"
              >
                Crear cuenta auditada
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead></Thead>
              <Tbody>
                <Flex
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Formik
                    initialValues={{
                      name: "",
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Requerido"),
                    })}
                    onSubmit={async (values, { resetForm }) => {
                      const gains = MonthlyGains.toShortFormat(monthlyGains);
                      try {
                        await API.graphql(
                          graphqlOperation(createAuditedAccounts, {
                            input: {
                              id: nanoid(),
                              name: values.name,
                              monthlyGains: gains,
                            },
                          }),
                        );
                        Swal.fire({
                          title: "Cuenta auditada creada",
                          text: "Por favor revisa la app",
                          icon: "success",
                          willClose: () => {
                            resetForm();
                            setMonthlyGains(MonthlyGains.initial(months));
                            updateData();
                          },
                        });
                      } catch (error) {
                        Swal.fire(
                          "Revisa e intenta de nuevo",
                          error.message,
                          "error",
                        );
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      resetForm,
                      values,
                    }) => (
                      <>
                        <form onSubmit={handleSubmit}>
                          <FormControl>
                            <TableInput
                              label={"Nombre"}
                              placeholder={"Nombre de la cuenta"}
                              name="name"
                              handleChange={handleChange}
                              value={values.name}
                              type="text"
                            />
                            <GainsInput
                              months={months}
                              monthlyGains={monthlyGains}
                              setMonthlyGains={setMonthlyGains}
                              lastMonthSelected={lastMonthSelected}
                              setLastMonthSelected={setLastMonthSelected}
                            />
                            <TableSubmitButtons
                              okLabel="Crear cuenta"
                              onReset={() => {
                                resetForm();
                                setMonthlyGains(MonthlyGains.initial(months));
                                setLastMonthSelected(0);
                              }}
                              disabled={isSubmitting}
                              onSubmit={handleSubmit}
                            />
                          </FormControl>
                        </form>

                        <Backdrop
                          open={isSubmitting || isLoading || loadingData}
                          style={{
                            zIndex: 20,
                            color: "#fff",
                          }}
                        >
                          <CircularProgress
                            color="inherit"
                            style={{ color: "#03CFB3" }}
                          />
                        </Backdrop>
                      </>
                    )}
                  </Formik>
                </Flex>
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  );
}
