// @ts-check
import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import { Img } from "react-image";
import { Loader } from "@aws-amplify/ui-react";
import { ItemContentNewPrincipal } from "components/Menu/ItemContentNewPrincipal";
import { FaEllipsisV } from "react-icons/fa";
import { API, graphqlOperation } from "aws-amplify";
import { deleteTutorial } from "graphql/mutations";
import { deleteTutorialBucket } from "./DeleteTutorialBucket";
import { ViewDetailsButton } from "components/Tables/ViewDetailsButton";

/**
 * @typedef {Object} TutorialsTableRowProps
 * @property {import("@aws-sdk/client-s3").S3Client} s3Client - The S3 client
 * @property {Tutorial} tutorial - The tutorial to display
 * @property {boolean} isLast - Whether this is the last row
 * @property {function} updateData - Function to update the table data
 */

/**
 * Table row component for Envivos
 * @param {TutorialsTableRowProps} props - input props
 * @returns {React.ReactElement}
 */
export function TutorialsTableRow({ s3Client, tutorial, isLast, updateData }) {
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function deleteTutorialHandler() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteTutorialBucket(s3Client, tutorial.bucketID);
          await API.graphql(
            graphqlOperation(deleteTutorial, { input: { id: tutorial.id } }),
          );
          Swal.fire("Eliminado!", "El tutorial ha sido eliminado.", "success");
          updateData();
        } catch (error) {
          Swal.fire("Error", "El tutorial no ha sido eliminado.", "error");
        }
      }
    });
  }

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="wrap">
          <Img
            crossorigin="anonymous"
            style={{ width: "15rem" }}
            src={tutorial.coverImage}
            // @ts-ignore
            loader={<Loader size="large" />}
          />
          <Flex direction="column" marginLeft={"2"} marginTop={"2"}>
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {tutorial.title}
            </Text>
            <Text
              fontSize="sm"
              color="gray.400"
              fontWeight="normal"
              noOfLines={2}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {tutorial.subtitle}
            </Text>
            <ViewDetailsButton
              title={tutorial.title}
              info={{ subtitle: tutorial.subtitle }}
            />
          </Flex>
        </Flex>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {!tutorial.publishedDate
            ? "N/A"
            : new Date(tutorial.publishedDate).toLocaleString("es-CO")}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {tutorial.coverImage}
        </Text>
      </Td>

      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Menu>
          <MenuButton marginLeft={"1rem"}>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList p="16px 8px">
            <Flex flexDirection="column">
              <MenuItem
                borderRadius="8px"
                mb="10px"
                onClick={deleteTutorialHandler}
              >
                <ItemContentNewPrincipal
                  type="delete"
                  onPress={deleteTutorialHandler}
                />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}
