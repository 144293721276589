import { Button, Flex, FormLabel, Select } from "@chakra-ui/react";
import { TableInput } from "components/Tables/TableInput";
import { useEffect, useState } from "react";
import { MonthlyGains } from "views/Dashboard/AuditedAccounts/GainsInput/MonthlyGains";
import { GoPlusCircle } from "react-icons/go";
import { FiMinusCircle } from "react-icons/fi";

/**
 * @typedef {Object} GainsInputProps
 * @property {{label: string;value: string;}[]} months - list of months to be used in the dropdown
 * @property {Record<string, string>} monthlyGains - object with monthly gains per month selected
 * @property {function} setMonthlyGains - function to set the monthly gains
 * @property {number} lastMonthSelected - index of the last month selected
 * @property {function} setLastMonthSelected - function to set the last month selected
 */
/**
 * GainsInput component props
 * @param {GainsInputProps} props - input props
 * @returns {React.ReactElement}
 */
export function GainsInput({
  months,
  monthlyGains,
  setMonthlyGains,
  lastMonthSelected,
  setLastMonthSelected,
}) {
  const [lms, setLMS] = useState(lastMonthSelected);
  const [monthlyGainsLocal, setMonthlyGainsLocal] = useState(monthlyGains);

  useEffect(() => {
    setLMS(lastMonthSelected);
  }, [lastMonthSelected]);

  useEffect(() => {
    setMonthlyGainsLocal(monthlyGains);
  }, [monthlyGains]);

  return (
    <>
      {Object.keys(monthlyGainsLocal)
        .sort((a, b) => parseInt(a.slice(0, -4)) - parseInt(b.slice(0, -4)))
        .map((index) => {
          return (
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              position="relative"
              key={index}
            >
              <Flex flexDirection="column" width="48%">
                <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
                  Mes
                </FormLabel>
                <Select
                  name={`monthlyGains${monthlyGainsLocal[index]}`}
                  onChange={(e) => {
                    setLastMonthSelected(
                      months.findIndex(
                        (monthObj) => monthObj.value === e.target.value,
                      ),
                    );
                    setMonthlyGains(
                      MonthlyGains.updateRow(
                        e.target.value,
                        "",
                        MonthlyGains.removeRow(index, monthlyGainsLocal),
                      ),
                    );
                  }}
                  value={index}
                >
                  {months.map((month) => {
                    return (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    );
                  })}
                </Select>
              </Flex>
              <TableInput
                label={"Ganancias"}
                placeholder={"Ganancias"}
                name={`monthlyGains${index}`}
                handleChange={(e) => {
                  setMonthlyGains((monthlyGainsLocal) =>
                    MonthlyGains.updateRow(
                      index,
                      e.target.value,
                      monthlyGainsLocal,
                    ),
                  );
                }}
                value={monthlyGainsLocal[index]}
                type="number"
                style={{ width: "48%" }}
              />
              {Object.keys(monthlyGainsLocal).length > 1 && (
                <Button
                  style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    position: "absolute",
                    right: "-60px",
                    top: "50%",
                    transform: "translateY(-33%)",
                  }}
                  onClick={() => {
                    setMonthlyGains((monthlyGainsLocal) =>
                      MonthlyGains.removeRow(index, monthlyGainsLocal),
                    );
                  }}
                >
                  <FiMinusCircle size={25} color="red" />
                </Button>
              )}
            </Flex>
          );
        })}
      <Button
        style={{
          marginBottom: "15px",
          backgroundColor: "rgba(0,0,0,0)",
          padding: "0px",
        }}
        onClick={() => {
          const nextI = MonthlyGains.nextMonth(lms, months);
          setLastMonthSelected(nextI);
          setMonthlyGains((monthlyGainsLocal) => ({
            ...monthlyGainsLocal,
            [months[nextI].value]: "",
          }));
        }}
      >
        <GoPlusCircle
          style={{ stroke: "green", strokeWidth: "0.3" }}
          size={30}
          color="green"
        />
      </Button>
    </>
  );
}
