// @ts-check
import { Flex } from "@chakra-ui/react";
import { listAuditedAccounts } from "graphql/queries";
import { AuditedAccountsListTable } from "./AuditedAccountsListTable";
import useGraphqlOperation from "hooks/useGraphqlOperation";
import { AuditedAccountsCreateForm } from "./AuditedAccountsCreateForm";

export function AuditedAccountsPage() {
  const { data, updateData, loadingData } =
    useGraphqlOperation(listAuditedAccounts);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <AuditedAccountsCreateForm
        updateData={updateData}
        loadingData={loadingData}
      />
      <AuditedAccountsListTable data={data} update={updateData} />
    </Flex>
  );
}
