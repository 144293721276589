// @ts-check
export const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

/**
 *
 * @param {number} [endMonth] - 0-indexed month
 * @param {number} [endYear] - final year
 * @returns {Array<{label: string, value: string}>} the list of months to be used in the dropdown
 */
export function generateMonthsList(endMonth, endYear) {
  const currentDate = new Date();
  endYear ??= currentDate.getFullYear();
  endMonth ??= currentDate.getMonth(); // 0-indexed

  const monthsList = [];

  let prefix = 0;

  for (let year = endYear - 2; year <= endYear; year++) {
    for (let month = 0; month < 12; month++) {
      // Only include months up to the current month for the current year
      if (year === endYear && month > endMonth) break;
      monthsList.push({
        label: `${monthNames[month]} ${year}`,
        value: `${prefix}${String(month + 1).padStart(2, "0")}${year.toString().slice(2)}`,
      });
      prefix += 1;
    }
  }

  return monthsList;
}
