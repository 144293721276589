// @ts-check
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";

import "antd/dist/antd.css";
import "./style.css";

import { HomeIcon } from "components/Icons/Icons";

import TablesSignals from "views/Dashboard/TablesSignals";
import Manual from "views/Dashboard/Manual";

import { HiUsers } from "react-icons/hi";
import { GiBookCover } from "react-icons/gi";
import { AiTwotoneNotification } from "react-icons/ai";
import { FaPlane, FaTools, FaUser } from "react-icons/fa";
import {
  BiBarChartAlt,
  BiBarChartSquare,
  BiMessageSquare,
  BiSolidVideoRecording,
} from "react-icons/bi";
import { BiNews } from "react-icons/bi";
import TablesNews from "views/Dashboard/TablesNews";
import TablesEnvivos from "views/Dashboard/TablesEnvivos";
import TablesTravelUsers from "views/Dashboard/TablesTravelUsers";
import TablesTravelActivations from "views/Dashboard/TablesTravelActivations";
import TablesMentalidad from "views/Dashboard/TablesMentalidad";
import TablesStrategy from "views/Dashboard/TablesStrategy";
import { TutorialsPage } from "views/Dashboard/Tutorials/TutorialsPage";
import { AuditedAccountsPage } from "views/Dashboard/AuditedAccounts/AuditedAccountsPage";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    name: "USUARIOS",
    category: "users",
    state: "pageCollapse",
    views: [
      {
        path: "/users",
        name: "Todos",
        icon: <HiUsers color="inherit" />,
        component: Tables,
        layout: "/admin",
      },
    ],
  },
  {
    name: "SEÑALES",
    category: "signals",
    state: "pageCollapse",
    views: [
      {
        path: "/all-signals",
        name: "Todas",
        icon: <AiTwotoneNotification color="inherit" />,
        component: TablesSignals,
        layout: "/admin",
      },
      {
        path: "/manual-mode",
        name: "Crear",
        icon: <FaTools color="inherit" />,
        component: Manual,
        layout: "/admin",
      },
    ],
  },
  {
    name: "VIAJES",
    category: "travels",
    state: "pageCollapse",
    views: [
      {
        path: "/all-travel-activations",
        name: "Activar Usuario",
        icon: <FaPlane color="inherit" />,
        component: TablesTravelActivations,
        layout: "/admin",
      },
      {
        path: "/all-travel-users",
        name: "Buscar Usuarios",
        icon: <FaUser color="inherit" />,
        component: TablesTravelUsers,
        layout: "/admin",
      },
    ],
  },
  {
    name: "NOTICIAS",
    category: "news",
    state: "pageCollapse",
    views: [
      {
        path: "/all-news",
        name: "Todas",
        icon: <BiNews color="inherit" />,
        component: TablesNews,
        layout: "/admin",
      },
    ],
  },
  {
    name: "EN VIVOS",
    category: "lives",
    state: "pageCollapse",
    views: [
      {
        path: "/all-lives",
        name: "Todos",
        icon: <BiSolidVideoRecording color="inherit" />,
        component: TablesEnvivos,
        layout: "/admin",
      },
    ],
  },
  {
    name: "TUTORIALES",
    category: "tutorials",
    state: "pageCollapse",
    views: [
      {
        path: "/tutorials",
        name: "Todos",
        icon: <GiBookCover color="inherit" />,
        component: TutorialsPage,
        layout: "/admin",
      },
    ],
  },
  {
    name: "SOCIAL TRADING",
    category: "social-trading",
    state: "pageCollapse",
    views: [
      {
        path: "/estrategias",
        name: "Estrategias",
        icon: <BiBarChartAlt color="inherit" />,
        component: TablesStrategy,
        layout: "/admin",
      },
    ],
  },
  {
    name: "CUENTAS AUDITADAS",
    category: "audited-accounts",
    state: "pageCollapse",
    views: [
      {
        path: "/cuentas-auditadas",
        name: "Todas",
        icon: <BiBarChartSquare color="inherit" />,
        component: AuditedAccountsPage,
        layout: "/admin",
      },
    ],
  },
  {
    name: "SALUD MENTAL",
    category: "mentalidad",
    state: "pageCollapse",
    views: [
      {
        path: "/mensajes-mentalidad",
        name: "Mensajes",
        icon: <BiMessageSquare color="inherit" />,
        component: TablesMentalidad,
        layout: "/admin",
      },
    ],
  },
];
export default dashRoutes;
