// @ts-check
import { useRef, Suspense, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  Flex,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Text,
  Input,
  Thead,
  useColorModeValue,
  Card,
} from "@chakra-ui/react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Loader } from "@aws-amplify/ui-react";
import { Img } from "react-image";
import { nanoid } from "nanoid";
import { LinearProgressWithLabel } from "components/LinearProgressWithLabel";
import { createTutorial } from "graphql/mutations";
import { TableSubmitButtons } from "components/Tables/TableSubmitButtons";

export function TutorialsCreateForm({ updateData, loadingData }) {
  const textColor = useColorModeValue("gray.700", "white");
  const [uploadProgress, setUploadProgress] = useState({});
  const formRef = useRef();

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Flex
        direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
        display="flex"
      >
        <Flex style={{ justifyContent: "center", alignItems: "center" }}>
          <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
            style={{ width: "40rem", padding: "1rem" }}
            pb="0px"
          >
            <CardHeader p="0px 0px 0px 0px">
              <Flex direction="column" w={{ sm: "100%" }}>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  pb=".5rem"
                >
                  Crear tutorial
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Table variant="simple" color={textColor}>
                <Thead></Thead>
                <Tbody>
                  <Flex
                    style={{
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Formik
                      id="formik"
                      innerRef={formRef}
                      initialValues={{
                        title: "",
                        subtitle: "",
                        description: "",
                        coverImage: "",
                        attachments: [],
                      }}
                      validationSchema={Yup.object({
                        title: Yup.string().required("Requerido"),
                        subtitle: Yup.string(),
                        description: Yup.string(),
                        coverImage: Yup.string(),
                      })}
                      validate={async (values) => {
                        if (
                          !values.attachments ||
                          values.attachments.length === 0
                        ) {
                          return {
                            attachments: "Seleccione mínimo un (1) archivo",
                          };
                        }
                      }}
                      onSubmit={async (values, { resetForm }) => {
                        try {
                          const filesID = nanoid();
                          const files = [];
                          for (const file of values.attachments) {
                            files.push(
                              Storage.put(`${filesID}/${file.name}`, file, {
                                level: "public",
                                progressCallback(progress) {
                                  setUploadProgress((prevProgress) => ({
                                    ...prevProgress,
                                    [file.name]: {
                                      progress: progress.loaded,
                                      total: progress.total,
                                    },
                                  }));
                                },
                              }),
                            );
                          }
                          await Promise.all(files);

                          await API.graphql(
                            graphqlOperation(createTutorial, {
                              input: {
                                title: values.title,
                                subtitle: values.subtitle,
                                description: values.description,
                                coverImage: values.coverImage,
                                publishedDate: new Date(),
                                bucketID: filesID,
                              },
                            }),
                          );
                          Swal.fire({
                            title: "Tutorial creado",
                            text: "Por favor revisa la app",
                            icon: "success",
                            willClose: () => {
                              resetForm();
                              setUploadProgress({});
                              document.getElementById(
                                "form_attachments",
                                // @ts-ignore
                              ).value = null;
                              updateData();
                            },
                          });
                        } catch (error) {
                          Swal.fire(
                            "Revisa e intenta de nuevo",
                            error.message,
                            "error",
                          );
                        }
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        values,
                        setFieldValue,
                      }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            <FormControl>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Titulo
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Titulo del tutorial"
                                  size="lg"
                                  id="form_title"
                                  name="title"
                                  onChange={handleChange}
                                  value={values.title}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="title" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Subtítulo
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Subtítulo del tutorial"
                                  size="lg"
                                  id="form_subtitle"
                                  name="subtitle"
                                  onChange={handleChange}
                                  value={values.subtitle}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="subtitle" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  URL Imagen Portada
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="URL de la imagen. Ej: https://... "
                                  size="lg"
                                  id="form_coverImage"
                                  name="coverImage"
                                  onChange={handleChange}
                                  value={values.coverImage}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="coverImage" />
                                </div>
                              </>
                              <Flex
                                width={"100%"}
                                justifyContent="center"
                                mt={"1rem"}
                              >
                                <Suspense>
                                  <Img
                                    crossorigin="anonymous"
                                    alt={"preview"}
                                    src={values.coverImage}
                                    style={{
                                      width: "15rem",
                                      height: "12rem",
                                      objectFit: "contain",
                                    }}
                                    loader={
                                      <Loader
                                        size="large"
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}
                                      />
                                    }
                                  />
                                </Suspense>
                              </Flex>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Archivos
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="file"
                                  multiple
                                  accept="video/*, application/pdf"
                                  size="lg"
                                  id="form_attachments"
                                  name="attachments"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "attachments",
                                      event.currentTarget.files,
                                    );
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="attachments" />
                                </div>
                              </>
                              {uploadProgress &&
                                Object.keys(uploadProgress).map((name) => (
                                  <>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Subiendo archivo: {name}
                                    </Typography>
                                    <LinearProgressWithLabel
                                      progress={uploadProgress[name].progress}
                                      total={uploadProgress[name].total}
                                    />
                                  </>
                                ))}
                              <TableSubmitButtons
                                okLabel="Crear tutorial"
                                disabled={isSubmitting}
                                onReset={() => {
                                  resetForm();
                                  setUploadProgress({});
                                  document.getElementById(
                                    "form_attachments",
                                    // @ts-ignore
                                  ).value = null;
                                }}
                                onSubmit={handleSubmit}
                              />
                            </FormControl>
                          </form>
                          <Backdrop
                            open={isSubmitting || loadingData}
                            style={{
                              zIndex: 20,
                              color: "#fff",
                            }}
                          >
                            <CircularProgress
                              color="inherit"
                              style={{ color: "#03CFB3" }}
                            />
                          </Backdrop>
                        </>
                      )}
                    </Formik>
                  </Flex>
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  );
}
